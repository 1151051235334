'use client';

import { useEffect, useState } from 'react';
import { userProfileAtom } from '@my-account/Atoms/UserProfile.atom';

import { useAtomValue } from 'jotai';

import { isFlagminOpenAtom } from './DevToolsListener';
import dynamic from 'next/dynamic';
import { FlagminModalProps } from './FlagminModal';

const FlagminModal = dynamic(() => import('./FlagminModal').then(mod => mod.FlagminModal), {
  ssr: false,
});

export const FlagminWrapper = (props: FlagminModalProps) => {
  const isOpen = useAtomValue(isFlagminOpenAtom);
  const { data: userProfile } = useAtomValue(userProfileAtom);

  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'production';

  useEffect(() => {
    if (isProd && !userProfile?.email.includes('@heycar.')) return;
    if (isOpen) setHasBeenOpened(true);
  }, [isOpen]);

  if (hasBeenOpened) return <FlagminModal {...props} />;

  return null;
};
